<template>
  <div class="h-full">
      <LoadingPage />
      <Toast v-if="this.$route.name === 'payment-link-list'" />
      <template v-if="currentRetailer">
          <router-view :key="$route.fullPath" v-show="!isLoading" />
      </template>
      <router-view v-else-if="!isLoading" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import LoadingPage from '../feature/loading/views/LoadingPage.vue';
import Toast from './common/Toast/index.vue';

export default {
  name: 'global-loading-wrapper',
  components: {
    LoadingPage,
    Toast,
  },
  computed: {
    ...mapState('paymentLink', ['newPaymentLink']),
    ...mapState('retailer', ['retailers', 'currentRetailer']),
    ...mapState('loading', ['isLoading']),
  },
  methods: {
    ...mapActions('retailer', ['getRetailers']),
    ...mapActions('loading', ['setIsLoading']),
  },
  async created() {
    if (this.retailers.length === 0) {
      this.setIsLoading({ isLoading: true });

      await this.getRetailers();
    }
    const currentPathIsPaymentLink = this.$route.fullPath.includes('link-de-pagamento');

    const newPaymentLinkIsEmpty = Object
      .values(this.newPaymentLink)
      .filter((value) => value)
      .length === 0;
    const currentRoute = this.$route.name;

    if (currentRoute === 'report-list') {
      await this.$router.push({ name: 'report-list' });
    }
    if (currentPathIsPaymentLink && currentRoute !== 'credit-check' && newPaymentLinkIsEmpty) {
      await this.$router.push({ name: 'payment-link-list' });
    }

    this.setIsLoading({ isLoading: false });
  },
};
</script>
